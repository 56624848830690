<template>
  <div class="main">
    <Banner/>
    <div class="video-page">
      <h1 v-html="video_details.title"></h1>
      <p class="date">{{video_details.date}}</p>
      <video controls :ref="'the_video'" @playing="played" @ended="finished">
        <source :src="video_details.video_url" type="video/mp4">
        <source :src="video_details.video_url" type="video/ogg">
        Your browser does not support HTML video.
      </video>
      <router-link class="button" :to="{name:'BusinessBuilder'}">SUBSCRIBE FOR MORE</router-link>
      <div id="disclaimer">
        <h3>LEGAL DISCLAIMER</h3>
        <p>Speakers assume no responsibility or liability regarding the actions taken or to be taken or losses incurred or to be incurred by participants or viewers based on information and content in this video/recording. Speakers have directly or indirectly prepared and created the content and the information on this video, for information purpose only.</p>
        <p>Speakers do not guarantee the information or assumption on the video to be correct, complete or up-to-date. Nothing herein is intended to give consultancy or any other services or to affiliate. All intellectual property rights in relation to this recording,  and any information or material are the exclusive property of the Speakers and these aforementioned cannot be copied, recorded by any audio or video recording device, screen capturing or screen recording device or any device capable of performing this function, reproduced or used without the written consent of the Speakers.</p>
        <p>3rd parties cannot use this presentation for advertising purposes, to solicit business or for any other purpose. Usage, copying and reproducing of all materials, content and information on this video is prohibited, unless permitted by Speakers in writing.</p>
        <p>It is strictly prohibited to reproduce, record, represent, display, modify, adapt or translate this recording and/or any work and material contained on this recording, in whole or in part, whether for free or for financial consideration, without Speakers’ prior written consent.</p>
        <p>This recording and all its content, is protected by the relevant law of United States, including in particular law of intellectual property law, and civil and criminal liability will arise in violation of the mentioned above.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";

export default {
  name: "Video",
  components:{ Banner},
  data:()=>({
    video_details:{
      title:"Miles Everson at Harvard Club of Boston:<br/>“How a Global CEO Reinvents the Workforce”",
      date:"July 16, 2021",
      video_url:"https://transcend-websites.s3.amazonaws.com/the-i-institute/videos/vr-miles-harvard-club-of-boston-2021-07-19.m4v",
    },
    was_played:false,
    has_left:false,
  }),
  methods:{

    played(){
      if(this.was_played === false){
        this.$gtag.event('User played the video', {
          'event_category': `Video - ${this.video_details.title}`,
          'event_label': `User played the video`,
        });
        this.was_played=true;
      }
    },
    finished(){
      this.$gtag.event('Finished the video', {
        'event_category': `Video - ${this.video_details.title}`,
        'event_label': `Reached the end of the video`,
      });
      this.has_finished=true;
    },
    left(){
      if(this.has_left === true) return;
      if(this.was_played && !this.has_finished){
        this.$gtag.event('Played the video but left', {
          'event_category': `Video - ${this.title}`,
          'event_label': `Navigated away from the Video page with the video at ${Math.floor( (this.$refs.the_video.currentTime / this.$refs.the_video.duration * 100) )}%`,
        });
        this.has_left=true;

      }
    },
    beforeDestroy() {
      this.left()
    },
    // pause(){
    //   this.$refs.webinar.pause()
    // },
  },
  mounted() {
    document.getElementById('app').classList.add('frameless');
  },
  beforeDestroy() {
    document.getElementById('app').classList.remove('frameless');

  }
  // beforeMount() {
  // let details = {};
  // switch (this.$route.params.uri){
  //   case 'this':
  //     break;
  //   default:
  //     this.$router.push({name:"Home"});
  // }
  // this.video_details = details;
  // },
  // computed:{
  //   video_details: function () {
  //
  //     return details;
  //   }
  // },
}
</script>
<style lang="scss">

.frameless{
  header, #banner, #sidebar, footer{
    display: none;
  }
  footer .container{
    max-width: 1000px;
  }
}
</style>
<style scoped lang="scss">
.main{
  padding-top: 80px;
}
header{
  display: none;
}

$max_width:1000px;
$base: 16;
@function getFontSize($size){

  @return ($size / $base) * 1em
}
.video-page{
  display: flex;
  max-width: $max_width;
  flex-direction: column;
  font-size: $base * 1px;
}
video{
  width: 100%;
  height: auto;
  margin: auto auto 29px;
}

#disclaimer{
  width: 100%;
  margin: 80px auto;
}
h3{
  text-align: center;
  margin-bottom: 29px;
  font-weight: bold;
  //font-size: 30px;
  font-size: getFontSize(30);
  font-family: $font_roboto;
}
p{
  font-family: $font_roboto;
  color: rgb(68, 68, 68);
  //font-size: 18px;
  font-size: getFontSize(18);
  line-height: 24px;
  margin-bottom: 26px;
}
h1{
  margin-bottom: 5px;
  font-size: getFontSize(32);
}
.date{
  //font-size: 18px;
  font-size: getFontSize(18);
  line-height: 29px;
  color: #777777;
  font-weight: 500;
}
.button{
  display: block;
  background-color: #003c79;
  color: #fff;
  font-weight: 700;
  font-size: 23.5px;
  max-width: 400px;
  width: 100%;
  margin: auto auto 29px;
}
@media screen and (max-width: $vw_2) {
  #disclaimer{
    p,h3{
      margin-bottom: 22px;
    }
  }
}
@media screen and (max-width: $vw_3) {
  .video-page{
    font-size: 8px;
  }
  .button{
    font-size: 14px;
  }
  p, .date{
    font-size: 14px;
    line-height: 16px;
  }
  #disclaimer{
    margin-top: 40px;
  }
}
</style>