<!-- this is being used in both program registration popup and standalone program registration page-->
<template>
  <div class="main" :key="program.id" style="overflow-x: hidden">
    <Banner style="margin:0;" v-if="program.banner=== undefined && $route.params.title !== undefined"/>
    <img v-if="program.banner!==undefined" :src="program.banner" />
    <div>
      <a @click="unselect()" v-if="$route.params.title === undefined">« Back</a>
      <div class="left">
        <h1>{{ program.title }}</h1>
        <div class="sub">
          <p>{{ (program.show_date)?program.event_date:'' }} {{ program.event_duration }}</p>
          <p>
            ${{ program.price }}
            <span>{{ program.promo }}</span>
          </p>
        </div>
        <div v-html="program.article" class="art"></div>
        <h2 v-if="registration_closed">Registration Closed</h2>
      </div>
      <template v-if="!registration_closed">
        <h2>Register Now</h2>
        <p>Limited slots available. Please register early to save your slot.</p>
        <form method="post" :action="reg_url">
          <input type="hidden" name="event_name" :value="program.title" readonly />
          <input type="hidden" name="event_date" :value="program.event_date" readonly />
          <input type="hidden" name="event_id" :value="program.id" readonly />
          <input type="hidden" name="redirect_url" :value="thank_you_url" readonly />
          <input type="hidden" name="has_payment " :value="program.has_payment" readonly />
          <div>
            <h4>BASIC INFORMATION</h4>
            <input type="text" name="fname" placeholder="First Name" required />
            <input type="text" name="lname" placeholder="Last Name" required />
            <input type="email" name="email" placeholder="E-mail Address" required />
          </div>
          <div>
            <h4>REGISTRATION QUESTIONS</h4>
            <textarea name="question_1" placeholder="How long have you been an independent?" required></textarea>
            <textarea name="question_2" placeholder="Why are you interested in pursuing this at this point in your independent journey?" required></textarea>
          </div>
          <button type="submit">REGISTER</button>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlainRegistrationForm",
  props:['program'],
  data:()=>({
    reg_url:'//register.i-inst.org',
    registration_closed:false,
  }),
  methods:{
    unselect(){
      this.$emit('unSelect')
    },
  },
  computed:{
    thank_you_url: function() {
      return window.location.origin + this.$router.resolve({name:'EventThankYouPage',params:{event_name:encodeURIComponent(this.program.title)}}).href;
    },
  },
  beforeUpdate() {
    let closing_date = new Date(this.program.closing_date);
    closing_date.setDate(closing_date.getDate() +1);
    let todate = new Date();
    if(todate.getTime() > closing_date.getTime() && this.program.show_date == 1){
      this.registration_closed = true;
    }else{
      this.registration_closed = false;
    }
  }
}
</script>
<style scoped lang="scss">

.full-width{
  >div{
    width: $global_width;
    padding-left:0!important;
    padding-right:0!important;
  }
}
.left{
  margin: 0 auto 30px;
  padding: 0;
}


a{
  display: inline-block;
  cursor: pointer;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: normal;
}
.popup{
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #00000066;
  top: 0;
  left: 0;
  z-index: 9999;
  p{
    font-size: 14px;
    text-align: center;
  }
}
h1{
  margin: 0 0 10px;
}
h2{
  color: #59b4e5;
  font-size: 32px;
  margin: 30px 0 15px;
  text-align: center;
}
.sub{
  color: #777777;
  margin-bottom: 30px;

  p{
    text-align: left;
    margin:0;
    font-size: 18px;
  }
  span{
    font-size: 14px;
  }
}
.main{
  >div:not(.main){
    width: unset;
    padding: 0 30px 50px;
    margin:auto;
    margin-top: 50px;
  }

}
form{
  display: flex;
  margin:0;
  h4{
    font-weight: bold;
    font-size: 16px;
    color: #7a7a7a;
    margin-top: 20px;
  }
  input,textarea{
    margin: 10px 0 0;
    width: unset;
  }
  button{
    width: unset;
    margin: 36px auto 0;
    padding:12px 120px
  }
  div{
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(50% - 30px);
  }
}
img{
  width: 100%;
  max-width: unset;
  @include skeleton;
}

@media screen and (max-width: $vw_4) {
  form{
    display: block;
    button{
      padding: 12px 0;
      width: 100%;
    }
  }
}

</style>

<style lang="scss">
.art, .definition {
  a{
    cursor: pointer;
    font-weight: normal;
    color: $med_blue!important;
    text-decoration: underline;
  }
  p,ul{
    font-size: 18px;
    line-height: 29px;
  }
}
</style>