<template>
  <div class="main">
    <Banner />
    <div class="article">
      <div class="left">
        <h1>Connect Now</h1>
        <p>
          Interested in enrolling in our programs?<br />Fill out this brief form
          and we’ll be in touch with next steps.
        </p>
        <form id="form" class="small">
          <input
            class="half"
            type="text"
            placeholder="First Name"
            name="first_name"
            v-model="first_name"
            required
          />
          <input
            class="half"
            type="text"
            placeholder="Last Name"
            name="last_name"
            v-model="last_name"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            v-model="email"
            required
          />
          <textarea
            v-model="anything_else"
            name="anything_else"
            placeholder="Anything else?"
          ></textarea>
          <input type="hidden" name="category" value="connect-now" />

          <!-- Please change to the proper website name -->
          <input type="hidden" name="website" value="I-Institute" />
          <button type="submit" @click="submit">SUBMIT</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs from "emailjs-com";

export default {
  name: "ContactUs",
  data: () => ({
    body: ``,
    first_name: "",
    last_name: "",
    email: "",
    anything_else: "",
  }),
  methods: {
    showErrorMsg() {
      this.$emit("showPopup", {
        title: "",
        body: "Something went wrong. Please try again later",
      });
    },
    redirect() {
      this.emailJS();
      this.$router.push({ name: "ThankYou" });
    },
    emailJS() {
      let service_id = "amazon_ses";
      let template_id = "transcend_talent";

      emailjs
        .sendForm(
          service_id,
          `${template_id}${process.env.VUE_APP_TESTING}`,
          "#form",
          process.env.VUE_APP_EMAILJS_ID
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    },
    async submit(e) {
      if (
        this.first_name.length &&
        this.email.length &&
        this.last_name.length
      ) {
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("website", "I-Institute"); // Please change to the proper website name
        formdata.append("category", "connect-now");
        formdata.append("first_name", this.first_name);
        formdata.append("last_name", this.last_name);
        formdata.append("email", this.email);
        formdata.append("anything_else", this.anything_else);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(`${process.env.VUE_APP_API_URL}`, requestOptions)
          .then(this.redirect)
          .catch(this.showErrorMsg);
      }
    },
  },
  beforeCreate() {
    document.title = "Connect Now - The I Institute";
  }, 
};
</script>
<style scoped lang="scss">
.left {
  margin: auto;
}
button {
  display: block;
  width: 100%;
}
</style>
