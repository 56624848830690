<template>
  <div class="popup hide" id="subscription-form" @click="popupBlocker">
    <div class="wrapper" @click="popupContent">
      <div class="popup-content" >
        <div class="popup-title">
          In order to sign up, please fill out all the fields below.
        </div>
        <!-- mc_embed_signup this code is from MailChimp SignupForm-->
        <div id="mc_embed_signup" class="tbbd-form-wrapper">
          <form action="https://valens-research.us10.list-manage.com/subscribe/post?u=657a38fea77d07feeaca80566&amp;id=36b93c64dc" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate tbbd-form" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
              <div class="mc-field-group form-group">
                <input type="text" value="" name="FNAME" class="required" id="mce-FNAME" placeholder="Name*">
              </div>
              <div class="mc-field-group form-group">
                <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="E-mail Address*">
              </div>
              <div class="mc-field-group form-group">
                <input type="text" value="" name="COMPANY" id="mce-COMPANY" placeholder="Company">
              </div>
              <p id="opt-message">If you would like to choose which dailies you want to receive, you can opt in from here:</p>
              <div class="mc-field-group form-group checkboxes">
                <label><input v-model="checkboxes" type="checkbox" value="1" name="group[14506][1]" id="mce-group[14506]-14506-0"><b>Monday</b><span>-</span><p>Monday Marvels</p></label>
                <label><input v-model="checkboxes" type="checkbox" value="2" name="group[14506][2]" id="mce-group[14506]-14506-1"><b>Tuesday</b><span>-</span><p>Return Driven Strategy</p></label>
                <label><input v-model="checkboxes" type="checkbox" value="4" name="group[14506][4]" id="mce-group[14506]-14506-2"><b>Wednesday</b><span>-</span><p>The Independent Investor</p></label>
                <label><input v-model="checkboxes" type="checkbox" value="8" name="group[14506][8]" id="mce-group[14506]-14506-3"><b>Thursday</b><span>-</span><p>Gorillas of Guerrilla Marketing</p></label>
                <label><input v-model="checkboxes" type="checkbox" value="16" name="group[14506][16]" id="mce-group[14506]-14506-4"><b>Friday</b><span>-</span><p>Mindfulness by Miles</p></label>
              </div>
              <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" >{{ (checkboxes.length == 0 )?'Please choose which dailies you want to receive':'' }}</div>
                <div class="response" id="mce-success-response" style="display:none"></div>
              </div>
              <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_657a38fea77d07feeaca80566_36b93c64dc" tabindex="-1" value=""></div>
              <div class="clear form-group"><input type="submit" value="SUBMIT" name="subscribe" id="mc-embedded-subscribe" class="button subscribe" :disabled="checkboxes.length <= 0"></div>
            </div>
          </form>
        </div>
        <!--End mc_embed_signup-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscriptionForm",
  data() {
    return {
      checkboxes:[1,2,4,8,16],
    }
  },
  methods:{
    popupBlocker() {
      document.querySelector(`#subscription-form`).classList.add("hide");

    },
    popupContent(e) {
      e.stopPropagation();
    },

  },

  created() {
    let ckeditor = document.createElement('script');
    ckeditor.setAttribute('src',"//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js");
    document.head.appendChild(ckeditor);

    window.formSuccessTimer = setInterval(() => {
      let successText = document.querySelector("#mce-success-response").textContent;
      console.log(successText);
      if(successText?.trim().length > 0) { // subscription success
        this.$emit('submitted');
        clearInterval(window.formSuccessTimer);
        setTimeout(() => {
          let subscriptionPopup = document.querySelector(`#subscription-form`);

          subscriptionPopup.classList.add("hide");

        }, 3000);
      }

    }, 1000);

  },

  beforeDestroy(){
    window.clearInterval(window.formSuccessTimer);
  },
}

</script>

<style scoped lang="scss">
.hide {
  display: none;
}
input{
  max-width: 100%;
}
.popup {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  font-family: $font_arial;

  .wrapper {
    position: relative;
    display: flex;
    background-color: #f8f8f8;
    top: 10%;
    max-width: 600px;
    min-width: $global_min_width;
    margin: auto;
    padding: 50px;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 65px 0px rgba(119,119,119,0.68);
    -moz-box-shadow: 0px 0px 65px 0px rgba(119,119,119,0.68);
    box-shadow: 0px 0px 65px 0px rgba(119,119,119,0.68);
    overflow-y: auto;
    max-height: 90%;
    .popup-content {
      width: 100%;
      .popup-title {
        font-size: 16px;
      }
    }
  }
}

#subscription-form.popup {
  .wrapper {
    max-width: 600px;
    .popup-content {
      .tbbd-form-wrapper {
        position: relative;

        .tbbd-form {
          margin-top: 20px;
          margin-bottom: 0;

          .form-group {
            margin-bottom: 10px;

            &:last-child {
              margin-top: 20px;
              margin-bottom: 0;
            }

            input[type="text"], input[type="email"] {
              font-family: $font-roboto;
              width: 100%;
              border: 2px solid #053b74;
              outline: 0;
              height: 40px;
              padding: 9px 30px;
              box-sizing: border-box;
              font-size: 20px;
              margin-bottom: 0;


              &.mce_inline_error {
                background-color: #fff;
                border: 2px solid #c00202;
              }
            }
            .subscribe {
              display: block;
              margin: auto;
              width: 100%;
              height: 40px;
              font-weight: bold;
              color: #fff;
              cursor: pointer;
              outline: 0;
              border: none;
              background-color: #e5a21f;
              font-family: inherit;
              font-size: 20px;
              padding: 0;
            }
          }
          #mce-success-response {
            text-align: center;
          }
        }


      }
    }
  }
}
.checkboxes{

  label{
    display: flex;
    font-size: 18px;
    margin-bottom: 10px;
    @include noHighlight;
    cursor: pointer;
  }
  input{
    display: inline-block;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin:0 10px 0 0;
    cursor: pointer;
  }
  span{
    margin: 0 5px;
  }
}
#opt-message{
  margin-top: 30px;
  margin-bottom: 10px;

}
@media screen and (max-width: 400px) {
  #subscription-form{
    width: 100%;
  }
}

@media screen and (max-width: 620px) {

  .popup .wrapper{
    padding: 32px;
  }
  .checkboxes{
    label{
      flex-wrap: wrap;
    }
    p{
      display: block;
      width: 100%;
      margin-left: 30px;

      flex-wrap: wrap;
    }
    span{
      display: none;
    }
  }
}
</style>