<template>
  <div class="main">
    <Banner/>
    <div class="article">
      <div class="left list">
        <div class="heading">
          <h1>Business Builder Daily Newsletters</h1>
          <!--          <input type="text" name="search" v-model="q" @input="search" placeholder="Search...">-->
        </div>
        <template v-for="(data,index) in this.active_newsletters">
          <router-link
              v-if="showItem(data)"
              :key="'a-'+index"
              :to="{name:'NewsLetter',params:{url_segment:data.url_segment}}"
              style="color:inherit"
          >
            <div class="img-container">
              <img :src="data.image_url" :key="'img-'+index"/>
            </div>
            <div>
              <h3 :key="'h2-'+index">{{data.title}}</h3>
              <h4 class="date" :key="'h3-'+index">{{parseDate(data.date_published)}}</h4>
              <p v-if="data.teaser">{{data.teaser}}</p>
            </div>
          </router-link>
        </template>
        <p v-if="(next_page > total_pages || (this.q.length && this.finished_loading)) && this.finished_loading===true" class="loading">No more newlsetter to load</p>
        <p v-else class="loading" @click="fetchQuotes()" v-text="(this.finished_loading === false)?'Loading...':'Click for more newsletters'"></p>
        <div class="subscribe">
          <img :src="require('@/assets/images/newsletter.png')">
          <p>Haven't subscribed yet? Click <router-link :to="{name:'BusinessBuilder'}">here</router-link> to receive the Business Builder Daily, straight to your inbox.</p>
        </div>
      </div>
      <div class="right">
        <div class="panel-container">
          <VCard
              v-for="(panel,index) in panels"
              :key="index"
              :title="panel.title"
              :message="panel.message"
              :img_src="panel.image"
              :link="panel.link"
              :class="panel.class"
              :popup_onclick="panel.popup_onclick"
          />
        </div>

      </div>
    </div>
    <SubscriptionForm/>
  </div>
</template>

<script>
import VCard from "@/components/VCard";
import SubscriptionForm from "@/components/SubscriptionForm";

export default {
  name: "Insights",
  components: {
    SubscriptionForm,
    VCard
  },


  data:()=>({
    main:``,
    active_newsletters: [],
    newsletters:[],
    q:'',
    previous_q:'',
    finished_loading:true,
    next_page:1,
    total_pages:1,
    panels:[
      {
        title:'SUBSCRIBE NOW',
        message:'to Business Builder Daily',
        // popup_onclick:true,
        link:{name:'BusinessBuilder'},
        class:'blue center',
      },
      {
        title:'Interested in enrolling in a program?',
        message:'Connect Now',
        link:{name:'ConnectNow'},
        class:'',
      },
    ],
    timeout:'',
  }),

  beforeMount() {
    this.fetchQuotes()
    // window.addEventListener('scroll', this.scrolledToBottom);
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.scrolledToBottom);
  },
  methods:{
    fetchQuotes(){
      if(!this.finished_loading) return;
      if(this.total_pages < this.next_page || this.q !== ''){
        return;
      }
      this.finished_loading = false;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic dmFsZW5zZGV2OnZhbGVuc2Rldg==");
      // myHeaders.append("Content-Type", "application/json");
      // myHeaders.append("Cookie", "__cfduid=de6edc8dcac8bca27ff8fe0fe731bcb711601958769");
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      fetch(`${process.env.VUE_APP_ADMIN_URL}api/newsletters/page/${this.next_page}`, requestOptions)
          .then(response => response.text())
          .then(result => {
            let results = JSON.parse(result);
            results.data.forEach((result)=>{
              this.newsletters.push(result);
            });
            this.finished_loading=true;
            this.total_pages = results.totalPages
            this.next_page++;
            this.active_newsletters = this.newsletters;
          })
          .catch(error => console.log('error', error));
    },
    scrolledToBottom(){
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 950 >= (document.documentElement.offsetHeight)
      if (bottomOfWindow && this.finished_loading) {
        this.fetchQuotes()
      }
    },
    parseDate(dt){
      if(dt !== undefined){
        dt = new Date(dt);
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return (monthNames[dt.getUTCMonth()])  + " " + dt.getUTCDate() + ", " + dt.getUTCFullYear();
      }
    },
    search(){
      clearTimeout(this.timeout)
      if(this.q === ''){
        this.active_quotes = this.quotes;
        return;
      }

      if(!this.finished_loading){
        return;
      }

      this.timeout = setTimeout(() => this.executeSearch(), 500)

    },
    executeSearch(){
      if(this.q === this.previous_q){
        return;
      }
      this.active_quotes = [];
      this.finished_loading=false;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer dmFsZW5zZGV2OnZhbGVuc2Rldg");
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      fetch(`${process.env.VUE_APP_ADMIN_URL}api/quote/search/?q=${encodeURIComponent(this.q.trim())}`, requestOptions)
          .then(response => response.text())
          .then(result => {
            var results = JSON.parse(result);
            if(results.status !== 'FAILED'){
              this.active_quotes =  results;
              this.previous_q = this.q;
            }

          })
          .catch(error => console.log(error))
          .finally(()=>{
            this.finished_loading=true;
          });
    }
  },
  beforeCreate() {
    document.title="Business Builder Daily Newsletters - The I Institute";
  },
}
</script>
<style scoped lang="scss">
h3{
  font-weight: 400;
  margin: 0 0 10px;
  font-size: 20px;
  line-height: 26px;
  font-family: arial, sans-serif;
}
p{
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}
.date{
  margin: 0 0 10px;
  color: #888;
  text-transform: uppercase;
  font-size: 16px;
  display:block;
  line-height: 20px;
}
a{
  display: flex;
  position: relative;
}
.img-container{
  margin-right: 30px;
}
img{
  margin:0;
  display: block;
  height: 120px;
  width: 120px;
  object-fit: cover;
  @include skeleton;
}
.loading{
  cursor: pointer;
}
</style>
