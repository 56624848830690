<template>
  <router-link class="panel" v-bind:class="(img_srcset!==false)?null:'mini'" :to="(link === undefined)?'':link" :target="(link_open_externally)?'_blank':''" @click.native="popup">
    <img
        v-if="(img_srcset !== undefined || img_src !== undefined)"
        :src="(img_srcset === undefined)?((img_src===undefined)?'':img_src):img_srcset.regular"
        :srcset="generateSrcset(img_srcset)"
    />
    <div>
      <h3 v-if="title !== undefined && title.length">{{title}}</h3>
      <p v-if="message !== undefined && message.length">{{message}}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Panel",
  props:['title','link','message','icon','img_srcset','link_open_externally','img_src','popup_onclick'],
  mounted() {
  },
  methods:{
    generateSrcset(img_srcset){

      if(img_srcset !== undefined && img_srcset.length > 1)
        return img_srcset.regular + ' 1x, ' + img_srcset.large + ' 2x';
      else
        return;
    },
    popup() {
      if(this.popup_onclick === true){
        document.querySelector(`#subscription-form`).classList.remove("hide");
      }
    },
  }
}
</script>
<style scoped lang="scss">

.panel{
  background-color: $gray;
  margin-bottom: 44px;
  text-decoration: none;
  @include noHighlight;
  &.blue{

    background-color: $dark_blue;
    color: #fff;
    h3{
      color: inherit;
      font-weight: 700;
    }
    p{
      color: inherit;
      font-size: 1em;
      font-weight: bold;
    }
  }
  &.center{
    text-align: center;
  }
}
.l img{
  height: auto;
}
img{
  width: 100%;
  height: 187px;
  object-fit: cover;
  object-position: center;
  @include skeleton;
}
div{
  padding: 16px 34px;
  width: 100%;
  box-sizing: border-box;
}
h3{
  font-weight: 400;
  font-size: 23.5px;
  margin-bottom: 6px;
  white-space: pre-wrap;
  color:$dark_blue;
}
p{
  font-size: 18px;
  color:$black;
}
a{
  display: block;
}
.biopic{
  img{
    height:auto;
    min-height: 200px;
  }
}
@media screen and (max-width: $vw_2) {
  img{
    min-height: 150px;
    height: 43vw;
  }
  .biopic{
    background-color: unset;
    text-align: center;
    img{
      height: 200px;
      width: 200px;
      border-radius: 50%;
    }
  }
}
</style>