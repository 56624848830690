<template>
  <header @mouseleave="hideall()">
    <div class="header-container">
      <router-link :to="{name:'Home'}" @mouseenter.native="hideall" class="logo"><img alt="The I Institute" src="@/assets/images/The I Institute-logo.png"></router-link>
      <nav>
        <div class="links">
          <div v-for="(nav,i) in menu" :key="i" @click="hideall()" @mouseenter="show(i)">
            <router-link :to="nav.route">{{ nav.text }}</router-link>
            <div class="dropdown-menu" v-if="nav.sub_menu !== undefined && nav.sub_menu.visible === true">
              <router-link v-for="(sub,j) in nav.sub_menu.routes" :to="sub.route" :key="nav.text + j">{{ sub.text }}</router-link>
            </div>
          </div>
        </div>
        <router-link :to="{name:'ConnectNow'}" class="item boxed" @mouseenter.native="hideall()">CONNECT NOW</router-link>
      </nav>
    </div>
  </header>
</template>
<script>
  export default {
    name: "Header",
    props:["menu"],
    methods:{
      hideall(){
        this.menu.forEach((menu)=>{
          if(menu.sub_menu !== undefined){
            menu.sub_menu.visible = false;
          }
        });
      },
      show(me){
        this.hideall();
        if(this.menu[me].sub_menu !== undefined){
          this.menu[me].sub_menu.visible = true;
        }
      }
    }
  }
</script>
<style scoped lang="scss">
header{
  height:$header_height;
  margin:auto;
  position:fixed;
  width:100%;
  top:0;
  background-color:$white;
  z-index:4;



}

.header-container{
  display:flex;
  justify-content: space-between;
  max-width:$document_max_width;
  margin:auto;
  width:$global_width;
  height: 100%;



}
a{
  display: block;
  line-height: 1em;
  color: $dark_blue;
  font-weight: 700;
  &:hover{
    color: $light_blue;
  }
}
img{
  height: 49.5px;
}
.logo{
  margin-top: 44px;
  height: fit-content;
}
nav{
  display:flex;
  align-items: center;
  height:100%;
  font-family: $font_roboto;
  font-size: 16px;
  text-transform: uppercase;
  a{
    text-decoration: none;
    font-weight: 700;
    color:$dark_blue;
    &:hover{
      color:$light_blue;
    }
  }
}
.links{
  display: flex;
  margin-left: auto;
  >div{
    height: fit-content;
    position: relative;
    top: 10px;
    left: 22px;
    margin-right: 30px;

    >div{
      position: absolute;
      background-color: #fff;
      width: max-content;
      border: 2px solid #eee;
      top: 54px;
      left: 50%;
      transform: translateX(-50%);
      color: #003c79;
    }
  }
}

.boxed{
  border: 2px solid #003c79;
  padding: 10px 24px;
  &:hover {
    color: #fff;
    background-color: #59b4e5;
    border-color: #59b4e5;
  }
}
.item{
  position:relative;
  height: auto;
  align-items: center;
  display:inline-block;
  margin: 20px 0 0 30px;
  white-space: nowrap;
}
.socmed-buttons{
  font-size: 40px;
  a{
    color:$light_blue;
    &:hover{
      color:$med_blue;
    }
  }
  a:not(:first-child){
    margin-left: 16px;
  }
}
.dropdown-menu{
  position: absolute;
  background-color:$white;
  width: max-content;
  top:55px;
  left: 50%;
  border: 2px solid #eee;
  transform: translateX(-50%);
  a{
    display: block;
    margin: 0 12px;
    padding: 10px 18px;
  }
  >a:not(:first-child),>div>a {
    border-top: 2px solid #eee;
  }
}
.floating-menu{
  position: relative;
  >div{
    width: max-content;
    background-color:$white;
    position: absolute;
    border: 2px solid #eee;
    left: 100%;
    top: 0;
  }
}

@media screen and (max-width: $vw_1){
  header{
    height:$mobile_header_height;
  }
  .logo{
    margin-top:0;
  }
  img{
    height:36px;
  }
  nav{
    display:none;
  }
  .header-container{
    align-items: center;
  }
}
@media screen and (max-width: $vw_2){
  nav{
    display: none;
  }
}
</style>
