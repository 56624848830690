<template>
  <div class="main">
    <Banner/>
    <Article
        :title="'Miles Everson’s Business Builder Daily'"
        :body="main"
        :subscribe="true"
        :panels="panels"
        id="newsletter"
    />
    <SubscriptionForm/>
  </div>
</template>

<script>
import Article from "@/components/Article";
import Banner from "@/components/Banner";
import SubscriptionForm from "@/components/SubscriptionForm";
export default {
  name: "NewsLetter",
  components: {SubscriptionForm, Article,Banner},
  metaInfo(){
    return {
      title: 'Miles Everson’s Business Builder Daily',
      titleTemplate: '%s - The I Institute',
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        {
          name: 'description',
          content: `${this.title}`,
        },
        {
          property:'og:title',
          content:`${this.title}`,

        },
        {
          property:'og:image',
          content:`${this.image}`,

        },
        {
          property:'og:description',
          content:`${this.title}`,

        },
        {
          property:'og:url',
          content:`${document.location.href}`,

        },
      ]
    }
  },
  methods:{
    truncateDescription(){
      // this.title = this.active_quote.quote;
      // if(this.active_quote.quote.length > 200){
      //   this.title = this.active_quote.quote.substring(0,197) + '...';
      // }
    },
  },
  data: () => ({
    main:``,
    title:'',
    description:'',
    image:'',
    panels:[
      {
        title:'SUBSCRIBE NOW',
        message:'to Business Builder Daily',
        // popup_onclick:true,
        link:{name:'BusinessBuilder'},
        class:'blue center',
      },
      {
        title:'Interested in enrolling in a program?',
        message:'Connect Now',
        link:{name:'ConnectNow'},
        class:'',

      },
    ],
  }),
  beforeMount() {
    //fetch the selected quote
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic dmFsZW5zZGV2OnZhbGVuc2Rldg==");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(`${process.env.VUE_APP_ADMIN_URL}api/newsletters/view/${this.$route.params.url_segment}` , requestOptions)
        .then(response => response.text())
        .then(result => {
          result = JSON.parse(result).body;
          this.title = result.title;
          this.image = result.image_url;
          this.truncateDescription();
          this.main=result.body;
        })
        .catch(error => console.log('error', error));

  },

}
</script>

<style lang="scss">

#newsletter .left{
  a:not(.button){
    text-decoration: underline;
  }
}

#newsletter p img {
  width: 100%;
  height: auto;
  margin:0;
}

table {
  tbody {
    tr {
      td {
        hr {
          border: 1px solid #e2e2e1;
        }
      }
    }
  }
}
</style>