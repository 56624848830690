<template>
  <div class="main">
    <Banner />
    <div class="article">
      <div class="left">
        <h1>Connect Now</h1>
        <p>
          Interested in enrolling in our Certified Self Employed course? Our CSE program will run from October 2nd-October 4th.
        </p>
        <p>Fill out this brief application and we’ll be in touch with next steps.</p>
        <form id="form" class="small">
          <input
              class="half"
              type="text"
              placeholder="First Name"
              name="first_name"
              v-model="first_name"
              required
          />
          <input
              class="half"
              type="text"
              placeholder="Last Name"
              name="last_name"
              v-model="last_name"
              required
          />
          <input
              type="email"
              name="email"
              placeholder="Email"
              v-model="email"
              required
          />
          <textarea
              v-model="anything_else"
              name="anything_else"
              placeholder="In this stage of your career why is it important for you to take this course?"
          ></textarea>
          <button type="submit" @click="submit">SUBMIT</button>
        </form>
        <h3>Admissions Criteria</h3>
        <p>The I Institute is committed to providing a world-class learning experience to applicants with the potential to benefit from, and contribute to the I Institute experience. The I Institute’s vision is to develop and support independent professionals, progressive enterprises and leaders of tomorrow to seek greater profitability, efficiency and fulfillment.</p>
        <p>The I Institute aims to offer equality of opportunity for all applicants. Participants are selected on the basis of individual merit and abilities. The Institute recognises that not all participants will have had equal opportunity to demonstrate their full academic potential, and the Institute aims to operate an admissions system that ensures fairness, transparency and equal opportunities to all applicants.</p>
        <p>Admission to the any I Institute program is subject to the availability of places. The I Institute Admissions Committee has established the following minimum criteria to help identify high quality, prospective candidates who would best benefit from the CSE curriculum:</p>
        <ul>
          <li>Actively engaged as, or soon to be, an Independent Professional</li>
          <li>Some familiarity with compliance/US tax codes</li>
        </ul>
        <p>The I Institute is committed to treating all applicants equally within the defined entry criteria set for each program, and in line with the institution’s equal opportunities values and policies. Applications are also considered solely on the defined entry criteria, and other factors such as religious, ethnic or racial background, gender, sexual orientation, sex, age or political considerations are not taken into consideration within the admission process.</p>

      </div>

    </div>
  </div>
</template>
<script>
import emailjs from "emailjs-com";

export default {
  name: "ContactUs",
  data: () => ({
    body: ``,
    first_name: "",
    last_name: "",
    email: "",
    anything_else:""
  }),
  methods: {
    showErrorMsg() {
      this.$emit("showPopup", {
        title: "",
        body: "Something went wrong. Please try again later",
      });
    },
    redirect() {
      this.emailJS();
      this.$router.push({ name: "ThankYou" });
    },
    emailJS() {
      let service_id = "amazon_ses";
      let template_id = "transcend";

      emailjs
          .sendForm(
              service_id,
              `${template_id}${process.env.VUE_APP_TESTING}`,
              "#form",
              process.env.VUE_APP_EMAILJS_ID
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => console.log(err));
    },
    async submit(e) {
      if (
          this.first_name.length &&
          this.email.length &&
          this.last_name.length
      ) {
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("category", "courses/enroll-now");
        formdata.append("first_name", this.first_name);
        formdata.append("last_name", this.last_name);
        formdata.append("email", this.email);
        formdata.append("anything_else", this.anything_else);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(`${process.env.VUE_APP_API_URL}`, requestOptions)
            .then(this.redirect)
            .catch(this.showErrorMsg);
      }
    },
  },
  beforeCreate() {
    document.title = "Connect Now - The I Institute";
  },
};
</script>
<style scoped lang="scss">
.left{
  margin: auto;
}
button{
  display: block;
  width: 100%;
}
</style>