import Vue from 'vue'
import Router from 'vue-router'
import VueGtag from "vue-gtag";
import Home from './views/Home'
import About from './views/About'
import AboutOwner from './views/AboutOwner'
import ThankYou from "./views/ThankYou";
import EnrollNow from "@/views/EnrollNow";
import ProgramForIndependents from "@/views/ProgramForIndependents";
import AdvisoryCouncil from "@/views/AdvisoryCouncil";
import Profile from "@/views/Profile";
import Facilitators from "@/views/Facilitators";
import BusinessBuilder from "@/views/BusinessBuilder";
import ConnectNow from "@/views/ConnectNow";
import NewsLetter from "@/views/NewsLetter";
import NewsList from "@/views/NewsList";
import BYFF from "@/views/BYFF";
import YTEmbed from "@/views/YTEmbed";
import Video from "@/views/Video";
// import Program from "@/views/Program";
// import Programs from "@/views/Programs";
// import Login from "./views/Login";
Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes:[
        {
            path:"/",
            name:'Home',
            component:Home,
        },

        {
            path:"/about/The-I-Institute",
            name:'About',
            component:About
        },
        {
            path:"/about/Emmett-Tracy",
            name:'AboutOwner',
            component:AboutOwner
        },
        {
            path:"/courses/enroll-now",
            redirect: '/connect-now',
            name:'EnrollNow',
            component:EnrollNow
        },
        {
            path:"/connect-now",
            name:'ConnectNow',
            component:ConnectNow
        },
        {
            path:"/programs",
            alias:"/other-programs",
            name:'ProgramForIndependents',
            component:ProgramForIndependents,
        },

        {
            path:"/programs/:title",
            name:'Program',
            component:ProgramForIndependents,
        },
        {
            path:"/advisory-board",
            name:'AdvisoryCouncil',
            component:AdvisoryCouncil
        },
        {
            path:"/facilitators",
            name:'Facilitators',
            component:Facilitators
        },
        {
            path:"/profiles/:name",
            name:'Profile',
            component:Profile,
        },
		{
            path:"/business-builder-daily",
            name:'BusinessBuilder',
            component:BusinessBuilder
        },
        {
            path:"/insights/business-builder-daily",
            name:'NewsList',
            component:NewsList
        },
		{
            path:"/insights/business-builder-daily/:url_segment",
            name:'NewsLetter',
            component:NewsLetter
        },

        {
            path:"/thank-you",
            name:'ThankYou',
            component:ThankYou
        },
        {
            path:"/thank-you/:event_name",
            name:'EventThankYouPage',
            component:ThankYou
        },
        {
            path:"/Build-Your-Financial-Future",
            name:'BYFF',
            component:BYFF
        },
        {
            path:"/miles-at-harvard-club-of-boston",
            // name:'YTEmbed',
            component:Video
        },
        {
            path:"/The_I_Institute_Guru_Marketing_May_19",
            alias:"/guru-marketing-1-day-left-to-register",
            name:'YTEmbed',
            component:YTEmbed
        },
        {
            path :'*',
            redirect:'/',
            component: Home
        },
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    },


})
Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GA_KEY },
    pageTrackerTemplate(to) {
        return {
            page_title: to.name ,
            page_path: to.path,
        }
    }
}, router);
export default router;
