import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF,faYoutube,faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueGtag from "vue-gtag";
import Banner from './components/Banner';
// import Card from './components/Card';
// import Article from './components/Article';
// import Panel from './components/Panel';
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
library.add(faFacebookF,faYoutube,faTwitter,faEnvelope,faCaretDown,faTimes)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_KEY,
  }
});
// custom global functions usage:`showItem(data)`
Vue.mixin({
  methods: {
    showItem: function (data) {
      // show the item if it doesn't have the published attribute
      if(data.published === undefined){
        return true;
      }
      // show the item if ENV value is DEV || OR || published attribute is true || 1
      if(process.env.VUE_APP_ENV === 'DEV' || data.published) {
        return true;
      }else{
        return false;
      }
    },
  },
});

Vue.component('Banner', Banner)
// Vue.component('Card', Card)
// Vue.component('Article', Article)
// Vue.component('Panel', Panel)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),

}).$mount('#app')
