<template>
  <div id="app" :style="minHeight">
    <Header :menu="menu" />
    <Sidebar :menu="menu"/>
    <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
    >
      <router-view :key="$route.fullPath" @showPopup="showPopup" />
    </transition>
    <Footer :menu="menu"/>
    <Popup :body="popup_content" :title="popup_title" :show_popup="show_popup" @shown="popupWasShown"/>
  </div>
</template>

<script>
import Header from './components/Header'
import Sidebar from './components/Sidebar'
import Footer from './components/Footer'
import Popup from './components/Popup'
export default {
  name: 'App',
  props:['page'],
  components: {
    Header,
    Sidebar,
    Footer,
    Popup,
  },
  data: () => ({
    minHeight:"min-height:" + screen.height*.8 + "px",
    show_popup:false,
    popup_content:'',
    popup_title:'',
    prevHeight: 0,
    menu:[
      {
        text:"PROGRAMS",
        route:{name:'Program',params:{title:'Build Your Financial Future! Simple & Powerful Investing Strategies for Individuals and Families'}},
        sub_menu:{
          visible:false,
          routes:[
            {text:"PROGRAM FOR INDIVIDUALS", route:{name:'ProgramForIndependents'}},

          ],
        }
      },
      {
        text:"PEOPLE",
        route:{name:'AdvisoryCouncil'},
        sub_menu: {
          visible: false,
          routes: [
            {text:"ADVISORY BOARD",route:{name:'AdvisoryCouncil'}},
            {text:"FACILITATORS",route:{name:'Facilitators'}},
          ],
        }
      },
      {
        text:"BUSINESS BUILDER DAILY",
        route:{name:'NewsList'},

      },
      {
        text:"ABOUT",
        route:{name:'About'},
        sub_menu: {
          visible: false,
          routes: [
            {text:"THE I INSTITUTE",route:{name:'About'}},
            {text:"EMMETT TRACY",route:{name:'Profile',params:{name:'Emmett Tracy'}}},
          ],
        }
      }
    ],
  }),
  methods:{
    showPopup(data){
      this.show_popup = true;
      this.popup_content = data.body
      this.popup_title = data.title
    },
    popupWasShown(){
      this.show_popup = false
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
  },
  beforeCreate() {
    document.title = "The I Institute"
  }
}
</script>
<!--
to show the popup, have this code in child component / views
this.$emit('showPopup', {title:'<title>',body:'<content>'});
-->
<style lang="scss">
@import "assets/scss/_defaults";
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
</style>
