<template>
  <div class="main">
    <div id="programs-banner-container">
      <img id="banner-wide" src="https://transcend-websites.s3.amazonaws.com/the-i-institute/build-your-financial-future-banner-wide.png" alt="Build Your Financial Future banner">
      <img id="banner-600" src="https://transcend-websites.s3.amazonaws.com/the-i-institute/build-your-financial-future-banner-600.png" alt="Build Your Financial Future banner">
    </div>
    <div id="programs-main-container">
      <div class="programs-section">
        <p>Building Your Financial Future requires significant knowledge of investing and financial planning and is designed to help independents learn how to sufficiently maximize their returns. This program is taught by one of the most successful accountants today and includes lessons from his years of experience on Wall Street and in the financial industry.</p>
        <p>Program outcomes for the two-hour session include:</p>
        <ul>
          <li>Successfully determining how much to invest and in fixed income, equity and securities</li>
          <li>Developing a sound understanding of your investment timetables</li>
          <li>Confidently applying individual and family investment strategies</li>
        </ul>
      </div>
      <div class="programs-section blue-banner">
        <h2>Register Now</h2>
        <div id="register-blurb">Limited slots available. Please register early to save your slot.</div>
      </div>
      <div class="programs-section">
        <form action="//register.i-inst.org" method="post" class="programs-grid-subsection">
          <div class="byff-form-container programs-grid-item">
            <input type="hidden" name="event_name" :value="me.title" readonly />
            <input type="hidden" name="event_date" :value="me.event_date" readonly />
            <input type="hidden" name="event_id" :value="me.id" readonly />
            <input type="hidden" name="redirect_url" :value="thank_you_url" readonly />
            <input type="hidden" name="has_payment " :value="me.has_payment" readonly />
            <h3>BASIC INFORMATION</h3>
            <div class="byff-form-control">
              <input type="text" name="fname" id="first_name" placeholder="First Name" required/>
            </div>
            <div class="byff-form-control">
              <input type="text" name="lname" id="last_name" placeholder="Last Name" required/>
            </div>
            <div class="byff-form-control">
              <input type="email" name="email" id="email_address" placeholder="E-mail Address" required/>
            </div>
          </div>
          <div class="byff-form-container programs-grid-item col-2">
            <h3>REGISTRATION QUESTIONS</h3>
            <div class="byff-form-control">
              <textarea type="text" name="question_1" id="how_long" placeholder="How long have you been an independent?" required></textarea>
            </div>
            <div class="byff-form-control">
              <textarea name="question_2" id="why_interested" placeholder="Why are you interested in pursuing this at this point in your independent journey?" required></textarea>
            </div>
          </div>
          <div class="programs-grid-item hide-on-960">

          </div>
          <div class="byff-form-container programs-grid-item button-container col-2">
            <div class="byff-form-control">
              <button type="submit">REGISTER</button>
            </div>
          </div>
          <div class="programs-grid-item col-3">
          </div>
          <div class="programs-grid-item speaker-img-container">
            <img src="https://transcend-websites.s3.amazonaws.com/the-i-institute/fybb-joel-circle.png" />
          </div>
          <div class="programs-grid-item col-2 speaker-details-container">
            <h3 class="speaker">Professor Joel Litman</h3>
            <p class="speaker-details">Professor Litman is Chair of the UAFRS Advisory Council which is spearheading the usage of
              Uniform Adjusted Financial Reporting Standards a.k.a. Uniform Accounting. He helped build Credit
              Suisse's HOLT University and the Center for S.E.V. and MBA Concentration at the Driehaus College
              of Commerce at DePaul University.</p>
          </div>

          <div class="programs-grid-item speaker-img-container">
            <img src="https://transcend-websites.s3.amazonaws.com/the-i-institute/fybb-emmett-circle.png" />
          </div>
          <div class="programs-grid-item col-2 speaker-details-container">
            <h3 class="speaker">Dr. Emmett P. Tracy</h3>
            <p class="speaker-details">Prior to founding The I Institute, Dr. Tracy worked extensively in global higher education in
              business, serving as Dean at the African Leadership University School of Business (ALUSB) in
              Kigali, Rwanda and Dean of Graduate Programs at Hult International Business School in Cambridge,
              Massachusetts. At Hult, Dr. Tracy also served as Programme Dean for the Finance Programme and
              Chair of the Finance Curriculum Committee.</p>
          </div>

          <div class="programs-grid-item col-3">

          </div>

          <div class="programs-grid-item quote-item">
            <div class="quote">“Unless you’re running billions of dollars and hiring the best talent, you’ll never see anything like this.”</div>
            <div class="quoter">Dr. John Sviokla</div>
            <div class="quoter-title">
              PwC: Management & Senior Partner<br />
              Vice Chairman of Diamond Management<br />
              Professor at Harvard Business School
            </div>
          </div>
          <div class="programs-grid-item quote-item">
            <div class="quote">“Some of the stock ideas [Joel] has recommended are really incredible with huge
              upside, and long before the rest of the Street sees it. This research has brought me significant
              gains in my portfolio.”
            </div>
            <div class="quoter">Matt Rakowski</div>
            <div class="quoter-title">Former VP of Goldman Sachs</div>
          </div>
          <div class="programs-grid-item quote-item">
            <div class="quote">“I can't imagine investing without his advice... My personal and family
              retirement portfolios are up more than 50% as a direct result of Joel Litman’s analysis and
              investment strategies. The research at the foundation of Altimetry is disciplined, practical,
              and confidence-building. I can’t imagine investing without it.”
            </div>
            <div class="quoter">Tom Gerencer</div>
            <div class="quoter-title">
              Award-winning Financial Journalist, whose work has been referenced by Fortune, ABC, NBC, BBC
              News, and The Economist
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BYFF",
  data:()=>({
    me:{
      id:'edm03112021',
      title:'Build Your Financial Future! Simple & Powerful Investing Strategies for Individuals and Families',
      definition:'This financial planning course is designed to teach independents how to secure their financial future through individual and family investing. Determining how much you invest in equities, fixed income, and near-term capital preservation securities, is the most important investment decision you can make. Through this course, you will learn how to invest in order to maximize your returns.',
      price:'15',
      event_date:'March 18, 2021',
      event_duration:'12:00 - 2:00 PM EST',
      has_payment:1,
      show_date:1,
    },
  }),

  computed:{
    thank_you_url: function() {
      return window.location.origin + this.$router.resolve({name:'EventThankYouPage',params:{event_name:encodeURIComponent(this.me.title)}}).href;
    },
  }
}
</script>

<style scoped lang="scss">

input,textarea{
  margin:0;
  font-weight: normal;
}

.main {
  margin: $header_height 0 0;
  padding: 0;
  font-family: Arial,sans-serif;
  font-size: 18px;
  line-height: 29px;
}
@media screen and (max-width: $vw_1) {
  .main{
    margin: $mobile_header_height 0 0;
  }
}

#programs-banner-container {
  text-align: center;
  width: 88.2%;
  max-width: 1230px;
  display: grid;

  img {
    width: 100%;
    max-width: 1230px;
    object-fit: cover;
    object-position: right top;
    height: 396px;
    @include skeleton;
  }
}

#programs-main-container {
  width: 88.2%;
  max-width: 1230px;
  margin: 0 auto 50px;
  background: #f5f5f5;

  p {
    margin: 1em 0;
  }

  .programs-section {
    padding: 50px 30px;
    border-left: 1px solid #747474;
    border-right: 1px solid #747474;
    border-bottom: 1px solid #747474;

    &:first-child {
      border-top: 1px solid #747474
    }

    &.blue-banner {
      background: #003c79;
      color: #fff;
      text-align: center;
      padding: 10px 30px;

      h2 {
        font-weight: bold;
        margin: 0;
        font-size: 24px;
        line-height: 130%;
      }
    }

    p:first-child {
      margin-top: 0;
    }
  }
}

#register-blurb {
  font-style: italic;
  font-size: 16px;
  line-height: 130%;
}

.programs-grid-subsection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.programs-grid-subsection h3 {
  font-size: 18px;
  line-height: 130%;
  font-weight: 500;
  margin: 0 0 10px;
  font-family: 'Roboto', sans-serif;
}

.programs-grid-item {
  &.col-2 {
    grid-column: span 2;
  }

  &.col-3 {
    grid-column: span 3;
  }

  .speaker {
    color: #1b4e74;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    font-family: 'Roboto', sans-serif;
    margin: 0;
  }

  .speaker-details {
    margin: 0.2em 0 1em;
  }

  .quote {
    font-family: 'Merriweather Sans', sans-serif;
    font-style: italic;
    color: #0a0a0a;
    font-size: 19px;
    margin-bottom: 20px;
  }

  .quoter {
    font-size: 20px;
    font-weight: 500;
    color: #1b4e74;
    font-family: 'Roboto', sans-serif;
  }

  .quoter-title {
    color: #777777;
    font-size: 16px;
    line-height: 140%;
    font-family: 'Roboto', sans-serif;
  }

  &.speaker-img-container,
  &.speaker-details-container {
    align-self: center;
    justify-self: center;
  }
}

.byff-form-control {
  margin: 0 0 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.byff-form-container {
  &.button-container {
    text-align: center;
  }

  input,
  textarea,
  button {
    font-family: inherit;
    font-size: inherit;
    line-height: 140%;
    border-radius: 0;
    border: 1px solid #000;
    padding: 5px 10px;
    width: 100%;
    box-sizing: border-box;
    resize: none;
    min-height: 40px;
    display: block;
  }
  textarea {
    height: 90px;
    margin-bottom: 0;
    &#how_long {
      height: 40px;
    }
  }
  button {
    width: auto;
    background: #ffa55b;
    color: #fff;
    border: 0;
    padding: 10px 30px;
    min-height: 40px;
    display: initial;
  }
}

@media screen and (max-width: 960px) {
  .hide-on-960 {
    display: none;
  }
  .programs-grid-item.quote-item {
    grid-column: span 3;
    div {
      width: 88.2%;
      max-width: 600px;
      margin: 0 auto;
    }
    .quote {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
  .programs-grid-item.byff-form-container,
  .programs-grid-item.speaker-img-container,
  .programs-grid-item.speaker-details-container {
    grid-column: span 3;
    text-align: center;
    width: 88.2%;
    max-width: 600px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 900px) {
  #programs-banner-container img {
    height: 350px;
  }
}
@media screen and (max-width: 800px) {
  #programs-banner-container img {
    height: 300px;
  }
}
@media screen and (max-width: 700px) {
  #programs-banner-container img {
    height: 250px;
  }
}

@media screen and (min-width: 600px) {
  #banner-600 {
    display: none;
  }
}
@media screen and (max-width: 600px) {

  #banner-wide {
    display: none;
  }
  #banner-600 {
    display: block;
  }

  .byff-form-container textarea#how_long {
    height: 90px;
  }

  .byff-form-container textarea#why_interested {
    height: 120px;
  }

  #programs-banner-container img {
    height: auto;
  }
}
</style>