import { render, staticRenderFns } from "./PlainRegistrationForm.vue?vue&type=template&id=08b95c50&scoped=true&"
import script from "./PlainRegistrationForm.vue?vue&type=script&lang=js&"
export * from "./PlainRegistrationForm.vue?vue&type=script&lang=js&"
import style0 from "./PlainRegistrationForm.vue?vue&type=style&index=0&id=08b95c50&scoped=true&lang=scss&"
import style1 from "./PlainRegistrationForm.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b95c50",
  null
  
)

export default component.exports