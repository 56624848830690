<template>
  <div class="main">
    <Banner/>
    <div class="page-container">
      <h1>
        The I Institute Guru Marketing - May 19
      </h1>
      <iframe width="1280" height="720" src="https://www.youtube.com/embed/nOBMJqoSmDs?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";

export default {
  name: "YTEmbed",
  components:{Banner}
}
</script>

<style scoped>
.page-container{
  max-width: 1000px;
}
  .main div{
    position: relative;
  }
  iframe{
    max-width: 1000px;
    width: 100%;
    height: 40vw;
    max-height: 562px;
    min-height: 159px;
    margin-bottom: 90px;
  }
</style>