<template>
  <div class="main">
    <Banner/>
    <h1>Advisory Board</h1>
    <div class="container">
      <Profile v-for="(profile,i) in profiles" :data="profile" :key="i"></Profile>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import Profile from "@/components/Profile";

export default {
  name: "AdvisoryCouncil",
  components:{Banner,Profile},
  data: () => ({
    profiles:[
      {
        name:'Miles Everson',
        img:require('@/assets/images/MilesEverson.png'),
        title:'Chairman of the Advisory Board',
      },
      {
        name:'Gene Zaino',
        img:require('@/assets/images/GeneZaino.png'),
        title:'MBO Partners',
      },
      {
        name:'Linda Mann',
        img:require('@/assets/images/LindaMann.jpg'),
        title:'MBO Partners',
      },
      {
        name:'Joel Litman',
        img:require('@/assets/images/JoelLitman.png'),
        title:'Valens Research',
      },
      {
        name:'Mawulom Essel-Koomson',
        img:require('@/assets/images/MawulomEssel-Koomson.jpg'),
        title:'The African Leadership University',
        disabled:true,
      },
      {
        name:'Adam Lyons',
        img:require('@/assets/images/AdamLyons.jpg'),
        title:'Harvard Law',
      },
      {
        name:'Kevin Koidl',
        img:require('@/assets/images/KevinKoidl.jpg'),
        title:'Trinity College Dublin',
      },
      {
        name:'Kenneth Schlechter',
        img:require('@/assets/images/KennethSchlechter.jpg'),
        title:'Kenneth Michael Consulting Services, University of Dayton, NYU',
      },
      {
        name:'Ken Hernandez',
        img:require('@/assets/images/KenHernandez.jpg'),
        title:'Titan Business Group',
      },
      {
        name:'Rodney Mann',
        img:require('@/assets/images/RodneyMann.png'),
        title:'Steeplechase Advisory Group',
      },
      {
        name:'Kevin Jackson',
        img:require('@/assets/images/KevinJackson.jpg'),
        title:'vCyber Services',
      },
      {
        name:'Kim Herbert',
        img:require('@/assets/images/KimHerbert.jpg'),
        title:'Caserta',
      },
      {
        name:'Denise Thomas',
        img:require('@/assets/images/DeniseThomas.png'),
        title:'The Effective Communication Coach',
      },
      // {
      //   name:'Johanna Shin',
      //   img:require('@/assets/images/JohannaShin.png'),
      //   title:''
      // },
      {
        name:'Marik Brockman',
        img:require('@/assets/images/MarikBrockman.png'),
        title:'Bravitas Advisory',
      },
      {
        name:'Lawson James',
        img:require('@/assets/images/LawsonJames.png'),
        title:'Horizon Projects Consulting Group',
      },
      {
        name:'Chris Greer',
        img:require('@/assets/images/ChrisGreer.png'),
        title:'Mozz',
      },
      {
        name:'Rik Reppe',
        img:require('@/assets/images/RikReppe.jpg'),
        title:'Experience Alchemists',
      },
      {
        name:'Tim Hanline',
        img:require('@/assets/images/TimHanline.jpg'),
        title:'Peredom',
      },
      {
        name:'Jordan Kendall',
        img:require('@/assets/images/JordanKendall.jpg'),
        title:'Starman Cybersecurity',
      },
       
    ]
  }),
  beforeCreate() {
    document.title = 'Advisory Board - The I Institute';
  }
}
</script>

<style scoped lang="scss">
.container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: $vw_1) {
  .container{
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: $vw_2) {
  .container{
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: $vw_5) {
  .container{
    grid-template-columns: 1fr;
  }
}
</style>