<template>
  <div class="main">
    <Banner/>
    <div id="facilitators" class="article">
      <div class="left">
        <h1>Facilitators</h1>
        <div>
          <Profile v-for="(profile,i) in profiles" :data="profile" :key="i"></Profile>
        </div>
      </div>
      <div class="right">
        <VCard :title="'Interested In Our Programs?'" :link="{name:'ConnectNow'}" :message="'Learn More'"></VCard>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import Profile from "@/components/Profile";
import VCard from "@/components/VCard";

export default {
  name: "Facilitators",
  components:{Banner,Profile,VCard},
  data: () => ({
    profiles:[
      {
        name:'Emmett Tracy',
        img:require('@/assets/images/EmmetTracy.png'),
        title:'Dean'
      },
      {
        name:'Miles Everson',
        img:require('@/assets/images/MilesEverson.png'),
        title:'Chairman of the Advisory Board'
      },
      {
        name:'Gene Zaino',
        img:require('@/assets/images/GeneZaino.png'),
        title:'Facilitator'
      },
      {
        name:'Joel Litman',
        img:require('@/assets/images/JoelLitman.png'),
        title:'Facilitator'
      },

    ]
  }),
  beforeCreate() {
    document.title = 'Facilitators - The I Institute';
  }
}
</script>

<style scoped lang="scss">
.left div{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
}

</style>
<style lang="scss">
@media screen and (min-width: $vw_5) {
  #facilitators{
    .left a{
      margin:0 auto 44px;
      flex: 0 0 42%;
    }
  }
}

</style>