<template>
  <div class="main">
    <Banner
      class="home"
      :html_content="this.banner_content"
      :isBannerLink="true"
    />
    <!-- isBannerLink prop would be for the CSE link -->
    <HCard
      v-for="(item, index) in items"
      :key="index"
      :class="item.class"
      :title="item.title"
      :img_srcset="item.img_srcset"
      :link="item.link"
      :message="item.message"
    />
  </div>
</template>

<script>
import HCard from "../components/HCard";

export default {
  name: "Home",
  components: {
    HCard,
  },
  data: () => ({
    banner_content: '',
    items: [
      {
        title: "Education for Independents",
        link: { name: "ProgramForIndependents" },
        img_srcset: {
          regular: require("@/assets/images/join@0.5x.jpg"),
          large: require("@/assets/images/join@0.75x.jpg"),
        },
        message:
          "The rising popularity of independent work is being hampered by an overlapping and inconsistent patch quilt of federal, state, and local laws, regulations, and administrative worker classification enforcement positions. Our programs provide continuous support to independent professionals, in order to prepare them for success in the workplace.",
      },
      {
        title: "What is The I Institute? ",
        link: { name: "About" },
        img_srcset: {
          regular: require("@/assets/images/utilize@0.75x.jpg"),
          large: require("@/assets/images/utilize.jpg"),
        },
        message:
          "Through programs designed to develop and support independent professionals and progressive enterprises, The I Institute educates, trains, and brings together passionate independent leaders and progressive enterprises who will transform the business landscape of tomorrow.",
      },
    ],
  }),
  beforeMount() {
    this.banner_content = `<div>
    <h1><b>Independent Thinking. Independent Design.</b><br>Explore how to develop your career as an Independent Professional.</h1>
    <a class="button" href="${this.$router.resolve({name:'ProgramForIndependents'}).href}">Learn More About<br>Our CSE Program</a>
</div>`;
  },
  beforeCreate() {
    document.title = "Home - The I Institute";
  },
};
</script>
