<template>
  <div id="banner" :style="banner">
    <div class="container" v-if="html_content !== undefined && html_content.length" v-html="html_content" >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props:['img','html_content'],
  data: () => ({
    banner: {
      backgroundImage: `url('${require("@/assets/images/desk.jpg")}')`,
    },
  }),
  beforeMount() {
    // custom/specified banner
    if (this.img !== undefined) {
      this.banner = { backgroundImage: `url('${this.img}')` };
    }
  },
};
</script>
<style lang="scss">
#banner {
  max-width: unset;
  width: 100%;
  height: $banner_height;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 66px;
  box-sizing: border-box;
  .container {
    position: relative;
    max-width: $document_max_width;
    width: $global_width;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    div {
      color: $white;
      width: 80%;
    }
  }
  .button {
    background-color: $white;
    color: $black;
    font-weight: bold;
    font-family: $font_arial;
    cursor: pointer;
    font-size: 16px;
    border: none;
    width: auto!important;
    &:hover {
      color: $white;
      background-color: #6d6e70;
    }
  }
}

@media screen and (max-width: 990px) {
  #banner {
    background-position-x: 80%;
    .container div{
      width: 100%;
    }
    h1{
      margin-bottom: 20px;
    }
  }

}
</style>
