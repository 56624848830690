<template>
  <div class="main">
    <Banner/>
    <Article :body="body" :panels="panels"/>
  </div>
</template>

<script>
import Article from '../components/Article'
export default {
  name: "AboutOwner",
  components: {
    Article,
  },
  data:()=>({
    body: `
      <p>Throughout his career Emmett Tracy has been a dedicated educator, with experience in preparing professionals for success in the workplace. Emmett is currently Dean of the African Leadership University in Rwanda. Prior to this role Emmett was the Dean of Postgraduate Programs at Hult International Business School in Cambridge Massachusetts.</p>
      <p>In 2020 Emmett founded The I Institute, with the vision of challenging the traditional to embrace the new world of work. Through his role as Dean, Emmett works to develop and support independent professionals, progressive enterprises, and leaders of tomorrow to seek greater profitability, efficiency and fulfillment.</p>
      <p>Tracy graduated from Williams College with a Bachelor of Arts. He then earned a Master of Fine Arts at Columbia University and a PhD at Trinity College in Dublin Ireland.</p>
    `,
    panels:[
      {
        title:'Emmett Tracy, Dean',
        link:'',
        img_srcset:{
          regular:require('@/assets/images/ChrisEmmet.jpg'),
        },
        class:'biopic',
      },
      {
        title:'Interested in enrolling in a course?',
        message:'Connect Now',
        link:{name:'ConnectNow'},

      }
    ],

  }),
  beforeCreate() {
    document.title = 'Emmett Tracy - The I Institute';
  }
}
</script>

