<template>
  <div class="program">
    <h2>{{ data.title }}</h2>
    <div class="sub">
      <p>
        {{ (data.show_date)?data.event_date:'' }}
        {{ data.event_duration }}
      </p>
    </div>
    <div class="definition" v-html="data.definition" />
    <button @click="selectProgram()">PROGRAM DETAILS</button>
  </div>
</template>

<script>
export default {
name: "Program",
  props:['data'],
  methods:{
  selectProgram(){

    this.$emit('setSelected');

    }
  },
}
</script>

<style scoped lang="scss">
.program{
  margin: 0 auto 100px;
}
h2{
  font-size: 40px;
  margin-bottom: 10px;
}
.upcoming h2{
  font-size: 28px;
}
.definition{
    font-size: 18px;
    line-height: 29px;
}
.sub{
  color: #777777;
  margin-bottom: 30px;
  p{
    font-size: 18px;
    line-height: 29px;
  }
  span{
    font-size: 14px;
  }
}
button{
  margin-top:30px;
  font-size: 24px;
  line-height: 1em;
  padding: 12px 68px;
}
@media screen and (max-width: $vw_3) {
  button{
    font-size: 15px;
    padding: 11px 0;
    line-height: 20px;
  }
}
</style>
