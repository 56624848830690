<template>
  <div class="card">
    <div class="container" >
      <img :src="img_srcset.regular" :srcset="generateSrcset(img_srcset)" v-if="img_srcset !== undefined && img_srcset.regular !== undefined">
      <div class="content">
        <h2 v-if="title" v-html="title"/>
        <div class="body" >
          <p v-html="message"></p>
        </div>
        <router-link class="button" v-if="(link !== '' && link !== undefined)" :to="link">{{(button_text)?button_text:'LEARN MORE'}}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Card',
  props: ['title', 'message','link','img_srcset','button_text'],
  methods:{
    generateSrcset(img_srcset){
      if(img_srcset.large !== undefined)
        return img_srcset.regular + ' 1x, ' + img_srcset.large + ' 2x';
      else
        return false;
    },
  }
}
</script>
<style scoped lang="scss">
.card{
  margin-bottom:80px;
  &.full{
    max-width: unset;
    width:100%;
    .container{
      max-width: $document_max_width;
      width:$global_width;
      margin:auto;
    }
  }
  &.gray{
    background-color:$gray!important;
    width:100%;
    padding:60px 0;
    margin:0;
  }
}
.container{
  display:flex;
  flex-direction: row;
}
img{
  width: 50%;
  height: 350px;
  max-width: 500px;
  flex-shrink: 0;
  object-fit: cover;
  object-position: center;
  order:1;
  margin:0 56px 0 0;
  @include skeleton;
}
.content{
  order:2;
  display:flex;
  flex-direction: column;

  margin-top:-7px;
}
.inverted{
  img{
    order:2;
    margin:0 0 0 56px;
  }
  .content{
    order:1;
  }
}
h2{
  font-size: 34px;
  line-height: 44px;
  margin-bottom: 25px;
  align-self:flex-start;
}

.body {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size:18px;
  line-height: 29px;
  margin-bottom: 30px;
  margin-top: 0;
}

.callout {
  margin:0 0 29px 0;
}
@media screen and (max-width: $vw_2){
  .card{
    margin-bottom: 40px;
    &.gray{
      margin:0;
      padding-top:40px;
      padding-bottom:40px;
    }
  }
  .container{
    display:block;
  }
  img{
    max-width: none;
    width: 100%;
    height:100%;
  }
  h2{
    font-size:21px;
  }
  .body{
    font-size: 16px;
    line-height: 22px;
  }
  img, .inverted img{
    margin: 0 0 8px 0;
  }
}
</style>
