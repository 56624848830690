<template>
  <div class="popup" v-if="program.title !== undefined && program.title.length > 0">
    <div class="container">
      <PlainRegistrationForm :program="program" style="padding: 0" @unSelect="unSelect"></PlainRegistrationForm>
    </div>
  </div>
</template>
<script>
import PlainRegistrationForm from "@/components/PlainRegistrationForm";
export default {
  name: "ProgramRegistrationForm",
  components: {PlainRegistrationForm},
  props:['program'],

  data:()=>({
    reg_url:'//register.i-inst.org',
    registration_closed:false,
  }),
  methods:{
    unSelect(){
      this.$emit('unSelect')
    }
  },
}
</script>
<style scoped lang="scss">
.popup{
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #00000066;
  top: 0;
  left: 0;
  z-index: 9999;
  p{
    font-size: 14px;
    text-align: center;
  }
}
.container{
  position: absolute;
  width: calc(100% - 70px);
  left: 50%;
  height: 100%;
  max-height: 100%;
  min-width: 300px;
  overflow: auto;
  transform: translateX(-50%);
  max-width: 890px;
  box-sizing: border-box;
  background-color: #fff;
}

</style>
