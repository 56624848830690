<template>
  <footer :style="{borderTop:(this.$route.name === 'BusinessBuilder')?'none':null}">
    <div class="container">
      <div class="top">
        <router-link to="{name:'Home'}"><img alt="The I Institute" class="logo" src="@/assets/images/The I Institute-logo.png"/></router-link>
        <div class="socmed-buttons">
          <img alt="MBO certified seal" src="@/assets/images/mbo-certified-seal-whitebg-lg.svg"/>
        </div>
      </div>
      <div class="bottom">
        <div class="links">
          <router-link v-for="(item,i) in menu" :key="i" :to="item.route" v-text="item.text" />
          <router-link :to="{ name: 'ConnectNow' }">CONNECT NOW</router-link>
        </div>
        <div id="copyrights">
          <p>&copy; {{ copyrightsYear() }} The I Institute. All rights reserved.</p>
          <p>
            Protected by copyright laws of the United States and international
            treaties. This website may only be used pursuant to the subscription
            agreement and any reproduction, copying, or redistribution
            (electronic or otherwise, including on the world wide web), in whole
            or in part, is strictly prohibited without the express written
            permission of The I Institute.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  props:['menu'],
  methods:{
    copyrightsYear(){
      return process.env.VUE_APP_COPYRIGHTS_YEAR || 2021
    },
  },
};
</script>
<style scoped lang="scss">
footer {
  border-top: solid 20px $gray;
}
.container {
  display: flex;
  flex-direction: column;
  width: $global_width;
  max-width: $document_max_width;
  margin: auto;
  padding-top: 28px;
}
.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 151px;
}
.bottom {
  margin-bottom: 45px;

  a {
    margin-right: 30px;
    color: $dark_blue;
    text-decoration: none;
    &:hover {
      color: $med_blue;
    }
  }
}
img {
  height: 49.5px;
}
.socmed-buttons {
  display: flex;
  align-items: center;
  img {
    height: 47.8px;
    margin-left: 14px;
    border-left: 3px solid #f3f3f3;
    padding: 10px 0 10px 37px;
  }
  a {
    font-size: 50px;
    line-height: 50px;
    margin-right: 24px;
    color: #ededed;
    &:hover {
      color: $light_blue;
    }
  }
}
.links {
  margin-bottom: 28px;
  font-family: Roboto, sans-serif;
  display: flex;
  flex-wrap: wrap;
}
#copyrights {
  font-size: 13px;
  line-height: 20px;
  color: #616263;
  p:nth-child(2) {
    color: #a9a8a9;
    width: 84%;
  }
}
@media screen and (max-width: $vw_3) {
  .socmed-buttons {
    display: none;
  }
  .top {
    margin-bottom: 58px;
  }
  img {
    height: 42.4px;
  }
  .links {
    margin-bottom: 15px;
    a {
      margin-right: 12px;
      font-size: 14px;
    }
  }
  #copyrights {
    font-size: 10px;
    line-height: 14px;
  }
}
@media screen and (max-width: $vw_5) {
  .links {
    display: block;
    a{
      display: block;
      line-height: 175%;
    }
  }
}
</style>
