<template>
  <div class="main" >
    <div class="row">
      <div class="text-container">
        <h1>Improve as an <span>Independent{{'\xa0'}}</span><span>Professional.</span><br/>Subscribe to Business Builder Daily.</h1>
        <p>Business Builder Daily has been designed to support and educate independent professionals on best practices within your field.</p>
        <button @click="popup" :disabled="subscribe_button_text === 'Thank you for subscribing!'">{{ subscribe_button_text }}</button>
        <hr/>
        <p>The Business Builder Daily has been created in collaboration with The I Institute, an education provider for independent professionals. The newsletter has been crafted with you, the independent professional, in mind.</p>
        <p>Every week we'll share our best tips for being an independent contractor, including tips on writing, marketing, and public speaking, and guidance from some of the top independent experts. Each day’s content has been carefully crafted to arm you with insights that will help you get your next gig, build your personal brand, and establish yourself as a leader and expert in your field.</p>
      </div>
      <div class="carousel-wrapper">
        <carousel :loop="true" :perPage="1" :autoplay="true" :autoplayTimeout="8000" :paginationPadding="2" paginationActiveColor="#6a8bad" paginationColor="#cdcdcd" >
          <slide v-for="category in categories" :key="category.day" :title="category.day">
            <img :src="require(`@/assets/images/${category.day}.jpg`)">

            <div class="text" v-html="`<h3><b>${category.day}: </b>${ category.title }</h3>${category.body}`">

            </div>
          </slide>
        </carousel>
      </div>
    </div>
    <div class=" gray">
      <div class="row">
        <div class="img-container">
          <img :src="require('@/assets/images/workplace.jpg')">
        </div>
        <div class="text-container">
          <div class="header">
            <img :src="require('@/assets/images/Miles Circle.png')">
            <h3>A Message from <span>Miles</span><span>Everson</span></h3>
          </div>
          <p>Independent Contractors make up more than one trillion of the U.S. economy. My time as CEO of MBO partners has given me a unique insight into the daily struggles of independent contractors. At MBO, we have dedicated the past two decades to providing independent contractors with the best services possible for their unique needs, enabling the future of work and improving the well-being of professionals and businesses throughout the world. I’m excited and honored to offer my expertise and learnings to independent contractors everywhere.</p>
          <p>Previously, I was with PricewaterhouseCoopers as Global Head of Advisory for 23 years, where I gained a wide range of skills necessary for consulting and leadership. Throughout my career, independent contractors and consultants taught me vital and influential lessons. I have collected an arsenal of knowledge that I want to share with independent contractors to further their careers.</p>
          <p>Such information is precisely what is highlighted in my newsletter, the Business Builder Daily. I have worked hard to provide you with the information and guidance that is of great value to any business builder. The Business Builder Daily shares inspiring stories of Marketing trailblazers, expert writing and public speaking advice, the greatest guerrilla marketing tactics and innovation.</p>
        </div>
      </div>

    </div>
    <SubscriptionForm @submitted="subscribed" />
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

import SubscriptionForm from "@/components/SubscriptionForm";
export default {
  name: "BusinessBuilder",
  components: {SubscriptionForm,  Carousel, Slide},
  data() {
    return {
      subscribe_button_text : 'SUBSCRIBE',
      popupActiveID: null,
      positioner:null,
      categories: [
        {
          day:"Monday",
          title:"Monday Marvels",
          body:`<p>Wondering how successful people work their way to the top of their career paths?</p><p>We got you covered!</p><p>Each Monday, we feature outstanding marketers and entrepreneurs of all time―their lives and experiences, as well as the lessons you can also apply in your life as an independent professional in order to succeed in your field of expertise.</p>`,
        },
        {
          day:"Tuesday",
          title:"Return Driven Strategy",
          body:`<p>Curious as to what the most affordable [free!] yet effective method of risk and error prevention is when it comes to allocating tasks to numerous different teams?</p><p>Allow us to introduce you to <i>Return Driven Strategy</i>!</p><p>Every Tuesday, we discuss different ways in which you can best implement Return Driven Strategy to your business to exponentially increase the efficiency and communication within your company.</p>`,
        },
        {
          day:"Wednesday",
          title:"The Independent Investor",
          body:`<p>Making an informed investment decision successfully is one of the most critical skills as both an independent investor and a business owner. Yet, sound financial advice for independent investors is not readily available, and the world of investing can easily seem daunting to the untrained eye.</p><p>Each Wednesday, with our new and meticulously researched articles, we aim to help you in your journey to becoming the best independent investor you can be by optimizing your time and assets!</p>`,
        },
        {
          day:"Thursday",
          title:"Gorillas of Guerrilla Marketing",
          body:`<p>Looking for inspiration on how to genuinely interact with your target market while also promoting your brand?</p><p>Here’s the answer you’ve been searching for!</p><p>Each Thursday, we highlight different Guerrilla Marketing tactics and fun brand activations created by businesses to generate buzz, achieve their marketing goals, and encourage customers and prospects to participate in the campaigns.</p>`,
        },
        {
          day:"Friday",
          title:"Mindfulness by Miles",
          body:`<p>Become a better version of yourself this year and in the next years to come! </p><p>Every Friday, we publish articles written in Miles Everson's (CEO of MBO Partners) voice. These articles contain his insights, advice, and goals for your overall wellbeing and personal growth. </p><p>We'll also feature events Miles has spoken at or write-ups he has been featured in. With these articles, we aim to help you optimize your performance in both your career and personal life.</p>`,
        }
      ],
    }
  },
  mounted() {
    this.positioner = setInterval(()=>{ this.setPaginationPosition(); }, 500);
  },
  beforeDestroy() {
    clearInterval(this.positioner);
  },
  methods: {
    subscribed(){
      this.subscribe_button_text = 'Thank you for subscribing!';
    },
    popup() {
      document.querySelector(`#subscription-form`).classList.remove("hide");
    },
    parseFilename(title){
      let filename = title.replace(':','');
      return filename + '.jpg';
    },
    setPaginationPosition(){
      console.log(1);
      let val;
      let target;
      try {
        target = document.querySelector(`.VueCarousel-pagination`);
        val = document.querySelector(`.VueCarousel-slide img`).height;
      }catch (e) {
        val = 35;
      }
      if(target !== null){
        target.style.top = `${val - 35}px`;
        target.style.display = `block`;
      }
    },
  },
  beforeCreate() {
    document.title = 'Business Builder Daily - The I Institute';
  }
}
</script>

<style scoped lang="scss">
button{
  font-size: 22px;
  margin-bottom: 30px;
  padding: 20px 18px;
}
hr{
  margin-bottom: 28px;
  height: 4px;
  background-color: #dddddd;
  border:none;
}
.row{
  margin-top: 44px;
  display:flex;
  margin-bottom: 78px;
  >div:first-of-type{
    margin-right: 110px;
  }
}
.text-container{
  flex: 1 1;
  *{
    letter-spacing: 0;
  }
  &:first-of-type p{
    &:first-of-type{
      color: #000;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 31px;
      max-width: 480px;
    }
  }
  p{
    font-family: $font_roboto;
    color: #444;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 26px;

  }

  h1{
    font-weight: bold;
    font-size: 30px;
    margin-top: -5px;
    margin-bottom: 26px;

  }
}
span{
  border-bottom: solid 8px #a8e1ff;
  line-height: 40%;
  padding: 0 2px;
  display: inline-block;
}
.gray{
  background-color: #f8f8f8;
  max-width: 100%;
  width: 100%;
  p{
    font-size: 16px;
    padding-left: 6px;
    padding-right: 3px;
    line-height: 26px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
  >.row{
    max-width: $document_max_width;
    width: $global_width;
    margin: auto;
    >div:first-of-type{
      margin-right: 100px;
    }
  }
  .img-container{

    flex: 0 1 39.265%;
    img{
      display: block;
      object-fit: cover;
      height: 100%;
      max-width: 100%;

    }
  }
  .text-container{
    padding-top: 50px;
    padding-bottom: 60px;
  }


  h3{
    font-size: 24px;
    font-weight: bold;
    span{
      border-bottom: solid 6px #a8e1ff;
      line-height: 40%;
    }
  }

}
.header{
  display: flex;
  color: #003c79;
  align-items: center;
  margin-bottom: 25px;
  img{
    margin-right: 19px;
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: $vw_5) {
  .header{
    display: block;
    text-align: center;
    img{
      margin: auto auto 29px;
    }
  }
}

@media screen and (max-width: $vw_2) {
  .row{
    display: block;
    >div{
      width: 100%;
      margin-bottom: 44px;
    }
  }
  .gray{
    p{
      padding:0;
    }
    .row{
      >div:first-of-type{
        display: none;
      }
    }
  }

}

</style>





<style lang="scss">
.VueCarousel-slide{
  img{
    max-width: 100%;
    width: 100%;
    min-height: 100px;
    display: block;
    position: absolute;
  }
}
.VueCarousel-dot-container{
  button{
    min-width: unset;
  }
}
.VueCarousel-pagination{
  position: absolute;
  top: 0;
  display: none;
  right: 12px;
  width: fit-content!important;
}
.carousel-wrapper{
  width: 39.265%;
  background-color: #f3f3f3;
  position: relative;
  .text{

    padding: 16px 34px;
    padding-top: 65%;
    *{
      letter-spacing: 0;
    }
  }
  p{
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 18px;
  }
  h3{
    font-size: 24px;
    color: #003c79;
    margin-bottom: 10px;
  }
}

</style>