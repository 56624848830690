<template>
  <div class="main">
    <Banner/>
    <Article :title="title" :body="body"/>
  </div>
</template>

<script>
import Article from "../components/Article";
export default {
  name: "ThankYou",
  components: {Article},
  data:()=>({
    title:"Thank You!",
    body: `<p>Thank you for your application, we’ll be in touch shortly!</p>`,
  }),
  beforeMount() {
    if(this.$route.params.event_name !== undefined){
      var ev = this.$route.params.event_name
      if(ev === 'Guru Marketing: Being & Building Your Personal Brand' ||
          ev === 'Leadership: Leading Remotely as an Independent' ||
          ev === 'Certified Self Employed - Certificate Program' ||
          ev === 'Build Your Financial Future! Simple & Powerful Investing Strategies for Individuals and Families'
      ){
        this.title = `Thanks! You're all set.`
        this.body = `<p>Thank you for registering for <b>${this.$route.params.event_name}</b>.</p><p>We'll get in touch with you once the program date is confirmed!</p>`
      }
    }
  }
}
</script>
