<template>
  <div class="article">
    <div class="left" :class="(hide_panels === true)?'fill':''">
      <h1 v-if="title" v-html="title" />
      <div v-html="body" />
      <router-link class="button" v-if="link" :to="link" >{{(button_text)?button_text:'LEARN MORE'}}</router-link>
      <a class="button" v-if="external_link" target="_blank" :href="external_link">{{(button_text)?button_text:'LEARN MORE'}}</a>
      <div class="subscribe" v-if="subscribe">
        <img :src="require('@/assets/images/newsletter.png')">
        <p>Haven't subscribed yet? Click <router-link :to="{name:'BusinessBuilder'}">here</router-link> to receive the Business Builder Daily, straight to your inbox.</p>
      </div>
    </div>
    <div class="right" v-if="hide_panels!==true">
      <VCard
          v-for="(panel,i) in panels"
          :class="panel.class"
          :key="i"
          :title="panel.title"
          :message="panel.message"
          :link="panel.link"
          :img_srcset="panel.img_srcset"
          :img_src="panel.img_src"
          :link_open_externally="panel.external_link"
          :popup_onclick="panel.popup_onclick"
      />
<!--      <VCard-->
<!--          v-if="default_panel!==false"-->
<!--          :title="'Find Out More'"-->
<!--          :message="'Reach out to Emmett Tracy with any questions about The I Institute.'"-->
<!--          :link="{name:'ContactUs'}"-->
<!--      />-->
    </div>
  </div>
</template>
<script>
import VCard from "@/components/VCard";

export default {
  name: 'Article',
  props:['title','link','body','external_link','button_text','panels','default_panel','hide_panels','subscribe'],
  components:{
    VCard
  },
  data: () => ({
  }),
  methods:{
  }
}
</script>
<style  lang="scss">
.left div:empty{
  min-height: 20px;
  @include skeleton
}
.article{
  display: flex;
  margin-bottom: 90px;
}
.left,.right{
  padding-bottom: 90px;
}
.alink {
  color: #003c79!important;
  text-decoration: underline!important;
}
.left{
  order:1;
  display: flex;
  flex-direction: column;
  padding-right: 57px;
  flex: 0 0 55%;
  h1{
    margin-bottom: 29px;
    line-height: 36px;
  }
  h2{
    font-size: 24px;
    margin-bottom: 29px;
  }
  h3{
    margin-bottom: 17px;
    font-weight: bold;
  }
  p{
    margin-bottom: 29px;
    line-height: 29px;
  }
  li{
    line-height: 29px;
  }
  img{
    width:100%;
    margin-bottom: 29px;
  }
  .loading{
    background-color: #f3f3f3;
    padding: 10px!important;
    text-align: center!important;
  }
  
  a:not(.button){
    color:inherit;
    font-weight: inherit;
    font-family: inherit;
  }
}
.right{
  order:2;
  display: flex;
  flex-direction: column;
  padding-left: 46px;
  border-left: 3px solid #e2e2e1;
  width: 100%;
}
.indented{
  padding: 0 1cm!important;
}
.fill{
  flex: 1 0 100%;
  padding:0;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: $vw_2) {
  .article{
    flex-direction: column;
  }
  .left,.right{
    width: 100%;
    border: none;
    padding: 0;

  }
  .left {
    margin-bottom: 44px;
    >*:last-child{
      margin-bottom: 0;
    }
    h1{
      line-height: 29px;
    }
    h2{
      font-size: 19px;
      line-height: 22px;
    }
    p{
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 22px;
    }
  }
}
</style>
