<template>
  <div class="main">
    <Banner/>
    <Article :body="body" :title="title" :panels="panels"/>
  </div>
</template>

<script>
import Article from '../components/Article'
export default {
  name: "About",
  components: {
    Article,
  },
  data:()=>({

    title:"The I Institute",
    body: `<p>The I Institute brings together the independent workforce, for training programs designed to set up independent professionals for continued success.</p><p>Navigating the new world of independent employment requires confidence, vision and an ambition to overcome competitive challenges, address strategy gaps and deliver tangible business results. At the I Institute a portfolio of programs and certifications have been designed with Independent professionals and enterprise organizations who employ Independent professionals in mind.</p><p>The I Institute will introduce you to some of the leading thinkers and visionaries of the Independent work force and help you or your organization navigate the career landscape of the 21st Century.</p><p>The Independent workforce accounts for nearly $1.3 trillion in US economic activity and by 2030 more than half of Americans will be, or have been, independent at some point in their career. The I Institute puts independents and progressive enterprises who understand the benefits of independent careers at the forefront of skills training, learning and development.</p>`,
    panels:[
      {
        title:'Interested in enrolling in a program?',
        message:'Connect Now',
        link:{name:'ConnectNow'},
        img_srcset:{
          regular:require('@/assets/images/utilize@0.75x.jpg'),
        }
      }
    ],
    to:"",
    items:[
      {
        title:'Talent',
        link:{name:'Talent'},
        img_srcset:{
          regular:require('@/assets/images/utilize@0.75x.jpg'),
          large:require('@/assets/images/utilize@0.75x.jpg'),
        },
        message:'Join the The I Institute  Community.',
        icon:false
      },
      {
        title:'Enterprises',
        link:{name:'Enterprises'},
        img_srcset:{
          regular:require('@/assets/images/group@0.5x.jpg'),
          large:require('@/assets/images/group@0.75x.jpg'),
        },
        message:'Utilize The I Institute Experts.',
        icon:false
      },

    ],
  }),
  beforeCreate() {
    document.title = 'About - The I Institute';
  }
}
</script>
