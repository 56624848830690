<template>
  <div id="sidebar">
    <div class="menu" :class="{ active: is_active }" @click="is_active = !is_active" @resize="closeSidebar">
      <hr/>
      <hr/>
    </div>
    <div id="sidebar-nav" :class="{ active: is_active }" >
      <div class="links">
        <div v-for="(nav,i) in menu" :key="i">
          <router-link @click.native="closeSidebar()" :to="nav.route">{{ nav.text }}</router-link>
          <font-awesome-icon v-if="nav.sub_menu !== undefined" :icon="{ prefix: 'fas', iconName: 'caret-down' }" class="caret-down" :class="{'active':nav.sub_menu.visible===true}" @click="show(i)" />
          <div class="dropdown-menu links" v-if="nav.sub_menu !== undefined && nav.sub_menu.visible === true">
            <router-link @click.native="closeSidebar()" v-for="(sub,j) in nav.sub_menu.routes" :to="sub.route" :key="nav.text + j">{{ sub.text }}</router-link>
          </div>
        </div>
      </div>
      <div class="gray links">
        <router-link :to="{name:'ConnectNow'}" @click.native="closeSidebar">CONNECT NOW</router-link>
      </div>
      <div class="bottom-icons">
        <img alt="MBO certified seal" src="@/assets/images/mbo-certified-seal-whitebg-lg.svg">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props:['menu'],
  data: () => ({
    is_active:false,
  }),
  beforeRouteUpdate() {
    this.closeSidebar();
  },
  methods:{
    closeSidebar(){
      this.is_active = false;
      this.hideall();
    },
    hideall(except){
      for(let i = 0; i<this.menu.length; i++){
        if(this.menu[i].sub_menu !== undefined && i !== except){
          this.menu[i].sub_menu.visible = false;
        }
      }
    },
    show(me){
      console.log(me);
      this.hideall(me);
      if(this.menu[me].sub_menu !== undefined){
        this.menu[me].sub_menu.visible = !this.menu[me].sub_menu.visible;
      }
    }
  },
  mounted() {
    // Register an event listener when the Vue component is ready
    window.addEventListener('resize', this.closeSidebar)
  },

}
</script>
<style scoped lang="scss">
$line_color:#d6d6d6;
$link_margin:8px;
#sidebar{
  @include noHighlight;
}
#sidebar{
  display: none;
  position: fixed;
  right:20px;
  z-index: 9999;
  top:$mobile_header_height;
}
a{
  color:$black;
  text-decoration: none;
  margin: $link_margin 0;
  align-self: flex-start;
}
.menu{
  cursor: pointer;
  position: fixed;
  top:20px;
  width:28px;
  right:20px;
  border-top: 2px solid #616161;
  border-bottom: 2px solid #616161;
  padding-top: 6px;
  padding-bottom: 8px;
  &.active{
    border-color:#00000000;
    transition: border .25s;
    hr:nth-child(1) {
      transform: rotate(45deg);
      transition: transform .5s;
    }
    hr:nth-child(2) {
      transform: rotate(-45deg);
      transition: transform .5s;
    }
  }
}
hr{
  position: absolute;
  width: 28px;
  background-color: #616161;
  height: 2px;
  border: none;
  transition: transform .5s;
  top: 6px;
}
#sidebar-nav{
  display: none;
  position:fixed;
  right:0;
  border-top: 3px solid #d6d6d6;
  width: 320px;
  background-color: #ededed;
  height:100vh;
  >.links{
    margin-bottom:6px;
    &:nth-of-type(2){
      a{
        display: block;
        border-bottom: 2px solid $white;
        margin:0;
        padding: 6px 0;
        width: 100%;
      }
    }
  }
}
#sidebar-nav.active{
  display: block;
}
.links {
  font-size: 14px;
  padding: 5px 18px 0;

  &:first-child {
    background-color: white;
  }

  div:last-child {
    border: none;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #d6d6d6;
    flex-wrap: wrap;
  }
}
.dropdown-menu {
  flex-direction: column;
  flex: 0 0 100%;
  > a {
    padding: $link_margin 0;
    margin: 0;

    &:not(:last-child) {
      border-bottom: 2px solid #d6d6d6;
    }
  }
  > a, > div {
    width: 100%;
    max-width: -webkit-fill-available;
  }
  &.links {
    padding: 0;
    margin-left: 14px;
  }
}


.caret-down{
  cursor: pointer;

  height:100%;
  color: #616161;
  font-size: 24px;
  transform: rotateX(0deg) ;
  transition: .5s;
  &.active{
    top:-3px;
    transform: rotateX(180deg) ;
  }
}
.bottom-icons{
  display: flex;
  position: absolute;
  bottom: 60px;
  height: 44px;
  background-color: $white;
  width: calc(100% - 32px);
  padding:16px;
  justify-content: space-between;
  align-items: center;
  a{
    margin-left: 16px;
    font-size: 36px;
    line-height: 36px;
    color: #d2d2d2;
  }
  img{
    height:40px;
  }
}
img{
  height:20px;
}
@media screen and (max-width: $vw_1){
  #sidebar{
    display: block;
  }
}
@media screen and (max-width: $vw_4) {
  #sidebar-nav {
    width: 100%;
  }
}
</style>
