<template>
  <div class="popup-container" v-show="is_visible && body.length">
    <div class="popup">
      <div class="title"><span>{{title}}</span><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" class="close" @click="hide"/></div>
      <div class="body">
        <p>{{body}}</p>
      </div>
      <div class="foot">
        <button @click="hide">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props:['title','body','show_popup'],
  data:()=>({
    is_visible: false,
  }),
  methods:{
    hide(){
      this.is_visible=false;
      this.$emit('shown');
    },
    show(){
      this.is_visible = true;
    },
  },
  watch:{
    show_popup: function(){
      if(this.show_popup) {
        this.is_visible = true;
      }

    },
  },

}
</script>
<!--
to show the popup, have this code in child component / views
this.$emit('showPopup', {title:'<title>',body:'<content>'});
-->
<style scoped lang="scss">
.popup-container{
  position: fixed;
  top:0;
  left:0;
  z-index:4;
  display:block;
  width:100vw;
  height:100vh;
  background-color: rgba(0,0,0,.2);

}
.popup{
  position: absolute;
  bottom:50%;
  left:50%;
  transform: translateX(-50%);
  border:solid 2px $gray_2;
  border-radius: 8px;
  min-height: 50px;
  background-color: $white;
  color:$black;
  padding-bottom:10px;
  font-size: 14px;
  .title{
    font-family: $font_roboto;
    padding:8px 8px 2px;
    //border-bottom: solid 2px $gray;
    span{
      height: 14px;
      display:inline-block;
    }
  }
  .body{
    font-family: $font_helvetica;
    padding:10px 20px;
    text-align: center;
    //white-space: pre-line;
  }
  .foot{
    text-align: center;
    font-family: $font_helvetica;
    padding-bottom: 10px;
  }
  .close{
    cursor: pointer;
    color: #616161;
    float: right;
    &:hover{
      color:$black;
    }
  }
  button{
    background-color: $white;
    border:solid 2px $gray_2;
    border-radius: 5px;
    cursor: pointer;
    padding:6px 12px;
    color: #616161;
    font-weight: normal;
    &:hover{
      color:$black;
      border-color: $black;
    }
  }
}
</style>