<template>
<div>
  <router-link :to="(data.disabled)?'':{name:'Profile',params:{name:data.name}}" :style="{cursor:(data.disabled)?'auto':'pointer'}">
    <img :src="data.img">
    <p>{{ data.name  }} <br> {{ data.title }}</p>
  </router-link>
</div>
</template>

<script>
export default {
name: "Profile",
  props:['data'],
}

</script>
<style scoped lang="scss">
a{
  display: block;
  margin: 0 22px 44px;
}
img{
  filter: grayscale(100%);
  display: block;
  height: 200px;
  width: 200px;
  //width: 100%;
  object-fit: cover;
  object-position: center;
  margin: 0 auto 20px;
  border-radius: 50%;
}
p{
  display: block;
  width: 200px;
  margin: 0 auto;
  color: $black;
  text-align: center;
}
//@media screen and (max-width: 1214px) {
//  a{
//    flex: 0 0 27%;
//  }
//}
//@media screen and (max-width: 914px) {
//  a{
//    flex: 0 0 40%;
//  }
//}
//@media screen and (max-width: $vw_5) {
//  a{
//    flex: 0 0 100%;
//  }
//}
</style>